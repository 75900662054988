/* CSS */
import "@assets/scss/all.scss";
/* JS */
import "@assets/js/lib/limet/jquery.cookies";
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'lazysizes';
import Typed from 'typed.js';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import SplitType from 'split-type'
// loads the Icon plugin
UIkit.use(Icons);

// Init jquery cookies
$('#cookie-consent').cookies();

//Init Browser-Check for Safari-Hacks
var ua = navigator.userAgent.toLowerCase();
if (ua.indexOf('safari') != -1) {
	if (ua.indexOf('chrome') > -1) {
		//alert("chrome") // Chrome
	} else {
		//alert("safari") // Safari
		$("body").addClass("safari");
	}
}

// Make header sticky on scroll
/*function makeHeaderSticky(var_sticky) {
	var window_y = window.pageYOffset;
	if( var_sticky ) {
		var sticky = var_sticky;
		//console.log('aus uebergabe');
	}
	var bottom = sticky + 700;
	//console.log('page_y: '+  window_y + ' - sticky_ab_y: ' + sticky + ' - bottom: '+bottom);

	if (window_y > sticky) {
		$("header").addClass("sticky");
		$("main").addClass("stick");
	} else {
		$("header").removeClass("sticky");
		$("main").removeClass("stick");
	}

	if (window_y > bottom) {
		$("header").addClass("scroll-hide--active");
	} else {
		$("header").removeClass("scroll-hide--active");
	}
}*/

/*function hideHeaderOnScroll() {
	var c, currentScrollTop = 0,
		navbar = $("header");
	$(window).scroll(function () {
		var a = $(window).scrollTop();
		var b = navbar.height();
		currentScrollTop = a;

		if (c < currentScrollTop && a > (b + b) ) {
			navbar.addClass("nav-up");
			//console.log('nav-up, weil '+c+' < '+currentScrollTop+' && '+a+' > ('+b+' + '+b+')');
		} else if (c > currentScrollTop && !(a <= b)) {
			navbar.removeClass("nav-up");
		}
		else{
			//Navigation normal machen
			//$("header").removeClass("sticky");
		}
		c = currentScrollTop;
	});
}*/

//Document-Ready-Funktionen
$(document).ready(function(){

	/*var sticky = $("header").offset().top;

	//get sticky-mode only if header isnt yet on top
	if(sticky > 0) {
		makeHeaderSticky(sticky);
		//hideHeaderOnScroll();
		console.log('make header sticky ');

		$(window).scroll(function() {
			makeHeaderSticky();
			hideHeaderOnScroll();
		});
		$(window).resize(function() {
			sticky = $("header").offset().top;
			//console.log('resize');
			makeHeaderSticky(sticky);
		});
	}*/
});

if($('.typoCtaSection .typed').length) {
	// Type-Animation in CTA-Section
	var typed = new Typed('.typoCtaSection .typed', {
		strings: typedString,
		typeSpeed: 80,
		startDelay: 1000,
		backSpeed: 70,
		backDelay: 2200,
		smartBackspace: true,
		loop: true,
		loopCount: Infinity,
		onComplete: function () {
			//$('.section-cta .typed-cursor').html('.').removeClass("typed-cursor--blink");
		},
		onBegin: function () {
			//$('.section-cta .typed-cursor').html('|').addClass("typed-cursor--blink");
		}
	});
}

(function($) {
	$.fn.phAnim = function( options ) {

		// Set default option
		var settings = $.extend({}, options),
			label,
			ph;

		// get label elem
		function getLabel(input) {
			return $(input).parent().find('label');
		}

		// generate an id
		function makeid() {
			var text = "";
			var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
			for( var i=0; i < 5; i++ )
				text += possible.charAt(Math.floor(Math.random() * possible.length));
			return text;
		}

		return this.each( function() {

			// check if the input has id or create one
			if( $(this).attr('id') == undefined ) {
				$(this).attr('id', makeid());
			}

			// check if elem has label or create one
			if( getLabel($(this)).length == 0 ) {
				// check if elem has placeholder
				if( $(this).attr('placeholder') != undefined ) {
					ph = $(this).attr('placeholder');
					$(this).attr('placeholder', '');
					// create a label with placeholder text
					$(this).parent().prepend('<label for='+ $(this).attr('id') +'>'+ ph +'</label>');
				}
			} else {
				// if elem has label remove placeholder
				$(this).attr('placeholder', '');
				// check label for attr or set it
				if(getLabel($(this)).attr('for') == undefined ) {
					getLabel($(this)).attr('for', $(this).attr('id'));
				}
			}

			$(this).on('focus', function() {
				label = getLabel($(this));
				label.addClass('active focusIn');
			}).on('focusout', function() {
				if( $(this).val() == '' ) {
					label.removeClass('active');
				}
				label.removeClass('focusIn');
			});
		});
	};
}($));


$('a.smooth').on('click',function(e) {
	e.preventDefault();
	var target = this.hash;
	var $target = $(target);
	$('html, body').stop().animate({
		'scrollTop': $target.offset().top
	}, 900, 'swing', function () {
		window.location.hash = target;
	});
});



// Funktion, um die Animation zu starten
function animateOnVisible(entries, observer) {
	entries.forEach(entry => {
		if (entry.isIntersecting) {
			entry.target.classList.add('animate'); // Klasse hinzufügen, um Animation zu starten
			console.log('interception :) ');
			observer.unobserve(entry.target); // Beenden der Beobachtung, um unnötige Aufrufe zu vermeiden
		}
	});
}

// IntersectionObserver initialisieren
let observer = new IntersectionObserver(animateOnVisible, {
	threshold: 0.1 // Startet die Animation, wenn 10% des Elements sichtbar sind
});

// Alle .js-split-text Elemente beobachten
document.querySelectorAll('.js-split-text').forEach(element => {
	observer.observe(element);
});

$(document).ready(function() {
	//$('.phanim input').val(''); //reset values which aren´t posted yet
	$('.phanim input').phAnim();

	//inject SplitType-functionality to animate text per line(s)
	if($('body .js-split-text').length) {
		document.querySelectorAll('body .js-split-text').forEach((element) => {
			let split = new SplitType(element, {
				split: 'words, lines'
			});
		});
	}

});

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log("HMR");
	});
}